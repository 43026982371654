import React from "react";
import Brandlogo from "../../assets/images/Helios_LOGO.png";
import icoarrowright from "../../assets/images/ico_arrow-right.svg";
import icocheked from "../../assets/images/ico-cheked.svg";
import logocopyright from "../../assets/images/logo-copyright.svg";
import fb from "../../assets/images/fb.svg";
import lin from "../../assets/images/in.svg";
import inst from "../../assets/images/inst.svg";
import bgblur2 from "../../assets/images/bg_blur-02.svg";

const Footer = () => {
  return (
    <>
      <footer className="section mod--footer">
        <div
          data-w-id="c2faefc5-6508-38a3-8cb4-a05433b3696a"
          className="content"
        >
          <div className="footer__columns mod--1">
            <div className="footer__col col--1">
              <a href="#" className="logo-link mod--footer w-inline-block">
                <img
                  src={Brandlogo}
                  loading="eager"
                  alt=""
                  className="logo mod--fopter"
                />
              </a>
            </div>
            <div className="footer__col col--2">
              <div className="footer__heading-wrap">
                <h2 className="heading mod--footer">
                  Still have questions?
                  <br />
                </h2>
                <a
                  data-remodal-target="form"
                  data-anim="btn"
                  href="http://"
                  className="footer__link-talk w-inline-block"
                >
                  <div className="overflow-hidden">
                    <div className="overflow__anim">
                      <div className="overflow__anim-txt mod--over mod--talk">
                        Let’s talk.
                      </div>
                      <div className="overflow__anim-txt">Let’s talk.</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="footer__columns mod--2">
            <div className="footer__col col--1">
              <div className="footer__form-wrap">
                <h4>Subscribe to newsleter</h4>
                <div className="from-block mod--subs w-form">
                  <form
                    id="wf-form-sender"
                    name="wf-form-sender"
                    data-name="sender"
                    method="get"
                    className="form"
                    aria-label="sender"
                  >
                    <input
                      type="email"
                      className="input mod--subs w-input"
                      maxLength={256}
                      name="email"
                      data-name="email"
                      placeholder="Email address"
                      id="email-4"
                      required=""
                    />
                    <div data-anim="btn-arrow" className="form__submit-wrap2">
                      <div className="form__submit-bg-square" />

                      <div
                        form-submitted="anim"
                        className="overflow-hidden mod--submit "
                      >
                        <div className="overflow__anim">
                          <img
                            loading="eager"
                            src={icoarrowright}
                            alt=""
                            className="overflow__anim-arrow mod--submit"
                          />
                          <img
                            loading="eager"
                            src={icoarrowright}
                            alt=""
                            className="overflow__anim-arrow mod--2 mod--submit"
                          />
                        </div>
                      </div>
                      <div className="form__submit-result-wrap">
                        <div
                          form-submitted="anim"
                          className="form__submit-result "
                        >
                          <img
                            src={icocheked}
                            loading="lazy"
                            alt=""
                            className="form__submit-result-ico"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex={-1}
                    role="region"
                    aria-label="sender success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex={-1}
                    role="region"
                    aria-label="sender failure"
                  >
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__col col--2">
              <div className="footer__nav-columns">
                <div className="footer__nav-col mod--1">
                  <h4>Website map</h4>
                  <div className="footer__grid">
                    <div
                      id="w-node-_12876181-4b13-d96c-de4d-cceb18652c6d-1b10fa2e"
                      className="footer__grid-block"
                    >
                      <a
                        href="#Shop"
                        data-anim="btn"
                        className="nav__link mod--footer w-inline-block"
                      >
                        <div className="overflow-hidden">
                          <div className="overflow__anim">
                            <div className="overflow__anim-txt mod--over">
                              Helios
                            </div>
                            <div className="overflow__anim-txt">Helios</div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      id="w-node-_3373777e-0061-fb7d-f761-e94940a42d40-1b10fa2e"
                      className="footer__grid-block"
                    >
                      <a
                        href="#Bestsellers"
                        data-anim="btn"
                        className="nav__link mod--footer w-inline-block"
                      >
                        <div className="overflow-hidden">
                          <div className="overflow__anim">
                            <div className="overflow__anim-txt mod--over">
                              What we do
                            </div>
                            <div className="overflow__anim-txt">What we do</div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      id="w-node-_1478bce9-b991-ebba-614a-f99441176a70-1b10fa2e"
                      className="footer__grid-block"
                    >
                      <a
                        href="#About"
                        data-anim="btn"
                        className="nav__link mod--footer w-inline-block"
                      >
                        <div className="overflow-hidden">
                          <div className="overflow__anim">
                            <div className="overflow__anim-txt mod--over">
                              About
                            </div>
                            <div className="overflow__anim-txt">About</div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      id="w-node-_2dd566b5-8538-a0d4-abcb-ca6636ec3e1f-1b10fa2e"
                      className="footer__grid-block"
                    >
                      <a
                        data-remodal-target="form"
                        data-anim="btn"
                        href="#"
                        className="nav__link mod--footer w-inline-block"
                      >
                        <div className="overflow-hidden">
                          <div className="overflow__anim">
                            <div className="overflow__anim-txt mod--over">
                              Helios
                            </div>
                            <div className="overflow__anim-txt">Achivers</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer__nav-col mod--2">
                  <h4>People</h4>
                  <div className="footer__grid">
                    <div className="from-block mod--subs w-form">
                      <form
                        id="wf-form-sender"
                        name="wf-form-sender"
                        data-name="sender"
                        method="get"
                        className="form footer__grid"
                      >
                        <input
                          type="text"
                          className="input mod--subs w-input"
                          maxLength={256}
                          name="email"
                          data-name="email"
                          placeholder="Name"
                          id="email-4"
                          required=""
                        />
                        <input
                          type="text"
                          className="input mod--subs w-input"
                          maxLength={256}
                          name="email"
                          data-name="email"
                          placeholder="Phone"
                          id="email-4"
                          required=""
                        />

                        <input
                          type="email"
                          className="input mod--subs w-input"
                          maxLength={256}
                          name="email"
                          data-name="email"
                          placeholder="Email address"
                          id="email-4"
                          required=""
                        />
                        <div className="genderradios">
                          <>
                            <p>
                              <input
                                type="radio"
                                id="test1"
                                name="radio-group"
                                defaultChecked=""
                              />
                              <label htmlFor="test1">Male</label>
                            </p>
                            <p>
                              <input
                                type="radio"
                                id="test2"
                                name="radio-group"
                              />
                              <label htmlFor="test2">Female</label>
                            </p>
                          </>
                        </div>

                        <div
                          data-anim="btn-arrow"
                          className="form__submit-wrap2"
                        >
                          <div className="form__submit-bg-square" />

                          <div
                            form-submitted="anim"
                            className="overflow-hidden mod--submit "
                          >
                            <div className="overflow__anim">
                              <img
                                loading="eager"
                                src={icoarrowright}
                                alt=""
                                className="overflow__anim-arrow mod--submit"
                              />
                              <img
                                loading="eager"
                                src={icoarrowright}
                                alt=""
                                className="overflow__anim-arrow mod--2 mod--submit"
                              />
                            </div>
                          </div>
                          <div className="form__submit-result-wrap">
                            <div
                              form-submitted="anim"
                              className="form__submit-result "
                            >
                              <img
                                src={icocheked}
                                loading="lazy"
                                alt=""
                                className="form__submit-result-ico"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__columns mod--3">
            <div className="footer__col col--1">
              <div className="footer__description">
                A platform for seamless SAT preparation with assessments and
                analytics.
              </div>
            </div>
            <div className="footer__col col--2">
              <div className="footer__nav-columns">
                <div className="footer__nav-col mod--1">
                  <a
                    href="http://halo-lab.com"
                    target="_blank"
                    className="footer__copyright-link w-inline-block"
                  >
                    <img
                      src={logocopyright}
                      loading="eager"
                      alt=""
                      className="footer__copyright-logo"
                    />
                    <div>
                      2022 Halo Lab <br />© All rights reserved
                    </div>
                  </a>
                </div>
                <div className="footer__nav-col mod--2">
                  <div className="footer__social">
                    <a
                      href=""
                      target="_blank"
                      className="footer__soc-link w-inline-block"
                    >
                      <img
                        src={fb}
                        loading="eager"
                        alt=""
                        className="footer__soc-img"
                      />
                    </a>

                    <a
                      href=""
                      target="_blank"
                      className="footer__soc-link w-inline-block"
                    >
                      <img
                        src={lin}
                        loading="eager"
                        alt=""
                        className="footer__soc-img"
                      />
                    </a>
                    <a
                      href=""
                      target="_blank"
                      className="footer__soc-link w-inline-block"
                    >
                      <img
                        src={inst}
                        loading="eager"
                        alt=""
                        className="footer__soc-img"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__follow-wrap"></div>
        <img src={bgblur2} loading="eager" alt="" className="footer__bg-blur" />
      </footer>
    </>
  );
};

export default Footer;
