import React, { useState } from "react";
import "./TestSkeliton.css";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import { useModelState } from "../../Custom_hooks/Custom";
import CustomModal from "../../CustomComponents/CustomModal/CustomModal";
import { useNavigate, useLocation } from "react-router";
import ClimbingBoxLoader from "react-spinners/ClipLoader";
import { PostRoute } from "../../Custom_hooks/Routes";
import { toast } from "react-toastify";
import { useMediaQuery } from "../../Custom_hooks/Custom";

const TestSkeliton = ({ tests, heading }) => {
  const isMobile = useMediaQuery("(max-width:700px)");
  const location = useLocation();
  const { user } = location.state || {};
  const userToAssignTest = user;
  const { isOpen, open, close } = useModelState();
  const navigate = useNavigate();
  const [selectedTests, setSelectedTests] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const getDateAndTime = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const extractedDate = dateObj.toLocaleDateString();
    const extractedTime = dateObj.toLocaleTimeString();
    return `${extractedDate} - ${extractedTime}`;
  };
  const handleSelectTests = (test) => {
    const isPresent = selectedTests.findIndex(
      (_test, index) => _test.testId === test._id
    );
    if (isPresent >= 0) {
      const updatedTests = selectedTests.filter(
        (_test, index) => _test.testId !== test._id
      );
      setSelectedTests(updatedTests);
    } else {
      setSelectedTests([
        ...selectedTests,
        {
          testId: test._id,
          testType: test.type,
          testName: test.name,
          testDescription: test.description,
          testStatus: "Pending",
          assignedAt: new Date(),
          answers: [],
          startTime: "",
          endTime: "",
        },
      ]);
    }
  };
  const handleSubmit = () => {
    if (selectedTests.length === 0) {
     return toast.error("No test selected . Please Select a test to assign.");
    }
    const bodyData = {
      assignedTests: selectedTests,
      userId: userToAssignTest._id,
    };
    PostRoute(
      "assignTest",
      () => {
        setLoader(true);
      },
      (res) => {
        console.log(res);
        setLoader(false);
        toast.success("Tests successfully assigned the student.");

        setSelectedTests([]);
      },
      bodyData
    );
  };

  const handleFindIsTestAlreadyAssigned = (test) => {
    if (!user) {
      return false;
    }
    const testIndex = user?.assignedTests.findIndex(
      (_test) => _test.testId === test._id
    );

    return testIndex === -1 ? false : true;
  };
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        close={close}
        open={open}
        style={{ width: "100%", height: "auto" }}
        data={tests}
      />

      <div className="testContainer">
        <div
          className="testHeader"
          style={{
            height: isMobile ? "auto" : "",
            alignItems: "center",
            display: "flex",
          }}
        >
          {!userToAssignTest && (
            <>
              <div className="testHeaderText"> {heading}</div>
            </>
          )}
          {userToAssignTest && (
            <div style={{ display: "flex", width: "75%" }}>
              <div className="userToAssignTestHeader">
                Assigning {heading} to {userToAssignTest?.name}
              </div>
              {loader ? (
                <>
                  <div
                    style={
                      !isMobile
                        ? {
                            position: "fixed",
                            right: "7%",
                            top: "5%",
                          }
                        : {
                            position: "fixed",
                            right: "7%",
                            top: "7%",
                          }
                    }
                  >
                    <ClimbingBoxLoader
                      color={"rgb(126, 23, 28)"}
                      loading={loader}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </>
              ) : (
                <CustomButton
                  text={"Assign Test"}
                  style={
                    !isMobile
                      ? {
                          width: "15%",
                          position: "fixed",
                          right: "2%",
                          marginTop: "0%",
                          marginLeft: "2%",
                          backgroundColor: "blue",
                        }
                      : {
                          width: "30%",
                          height: "32px",
                          position: "fixed",
                          right: "2%",
                          marginTop: "0%",
                          marginLeft: "2%",
                          backgroundColor: "blue",
                        }
                  }
                  // isDissabled={selectedTests.length === 0}
                  handleClick={() => {
                    handleSubmit();
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="allTestsContainer" style={{ height: "87vh" }}>
          {tests.length > 0 ? (
            tests.map((test, index) => {
              return (
                <>
                  <div
                    className="testElementsContainer"
                    style={{
                      opacity: handleFindIsTestAlreadyAssigned(test) ? 0.5 : 1,
                      cursor: handleFindIsTestAlreadyAssigned(test)
                        ? "not-allowed"
                        : "pointer",
                    }}
                    title={
                      handleFindIsTestAlreadyAssigned(test)
                        ? "Test already assigned."
                        : "Click to select the test to assign."
                    }
                  >
                    <div className="elementContainer">
                      <div className="testNameInfo">{test.name}</div>
                      <div className="testOtherInfo">{test.description}</div>
                      <div className="testStatusContainer">
                        <div className="statusItemContainer">
                          <div className="statusItemHeader">Uploaded At</div>
                          <div className="statusItemValue">
                            {getDateAndTime(test.uploadedAt)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {userToAssignTest && (
                      <input
                        type="checkbox"
                        name={`checkBox${index}`}
                        id={`checkBox${index}`}
                        checked={selectedTests.some(
                          (_test, index) => _test.testId === test._id
                        )}
                        onChange={() => handleSelectTests(test)}
                        className="checkBoxBtn shake"
                        disabled={handleFindIsTestAlreadyAssigned(test)}
                      />
                    )}
                  </div>
                </>
              );
            })
          ) : (
            <div className="noTestMessage">No {heading} Uploaded !!!</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TestSkeliton;
